<template>
    <div class="grid grid-cols-12 gap-4">
        <div class="col-span-12">   
            <label class="mb-2 inline-block">Perangkat</label>
            <v-select :options="devices" v-model="device_id" multiple :reduce="devices => devices.id" label="name" style="z-index:100000 !important" />
        </div>
        <div class="col-span-12 w-full">
            <label class="mb-2 inline-block">Periode Awal</label>
            <input type="date" name="" id="" v-model="start_date" class="rounded border border-gray-100 px-4 py-2 w-full">
        </div>
        <div class="col-span-12 w-full">
            <label class="mb-2 inline-block">Periode Akhir</label>
            <input type="date" name="" id="" v-model="end_date" class="rounded border border-gray-100 px-4 py-2 w-full">
        </div>
        <div class="col-span-12">
            <button class="px-4 py-3 text-white w-full bg-green-500 rounded hover:bg-green-600" @click="reportExcel()">Export</button>
        </div>
    </div>
</template>
<script>
var fileDownload = require('js-file-download');
import moment from 'moment'
import globalMixin from '../../mixin/global'

export default {
    props:['devices'],
    data: () => ({
        start_date:moment().startOf('month').format('YYYY-MM-DD').toString(),
        end_date:moment().endOf('month').format('YYYY-MM-DD').toString(),
        device_id:[]
    }),
    mixins:[globalMixin],
    methods:{
          async reportExcel() {
            let device_id = ''
            await this.device_id.forEach(x => {
                device_id = device_id + '&device_id[]=' + x
            })
            this.config.responseType = 'blob'
            this.axios.get(`v1/export_file/group?start_date=${this.start_date}&end_date=${this.end_date}${device_id}`, this.config)
            .then(ress => { 
                fileDownload(ress.data,'Report.xlsx')
            })
        },
    }
}
</script>