export default {
    data: () => ({
        optionBarLevel:
            {
            series: [],
            animations: {
                enabled: true,
                easing: 'linear',
                dynamicAnimation: {
                    speed: 1000
                }
            },
            labels:[2001, 2002, 2003, 2004, 2005, 2006, 2007],
            chart: {
                type: 'bar',
                // stacked: false,
                height: 200,
                zoom: {
                    type: 'x',
                    enabled: true,
                    autoScaleYaxis: true
                },
                toolbar: {
                    autoSelected: 'zoom'
                }
            },
         
            markers: {
                size: 0,
            },
            plotOptions: {
                bar: {
                  dataLabels: {
                    position: 'top', // top, center, bottom
                  },
                }
              },
            dataLabels: {
                enabled: true,
                formatter: function(value, { series, seriesIndex, dataPointIndex, w }) {
               
                  return value + 'cm' 
                },
                offsetY: -20,
                  style: {
                    fontSize: '10px',
                    colors: ['#60A5FA']
                  },
                  
            },
            title: {
                
                align: 'left'
            },
            colors: ['#60A5FA'],
            yaxis: {
              labels:{
                formatter: (value) => { return value.toFixed(0) },
              },
            },
            tooltip: {
                // x: {
                //     format: 'y-MM-dd HH:mm:ss',
                // },
                 y: {
                    formatter: function(value, { series, seriesIndex, dataPointIndex, w }) {
                      
                      return value + ' ' + 'cm'
                    }
                  }
            },
            
            stroke: {
                show: true,
                curve: 'stepline',
                lineCap: 'square',
                width: 2,
                dashArray: 0,      
            }
        },
    }),
}