<template>
    <div class="grid grid-cols-12 gap-4">
        <div class="col-span-4 w-full">
            <input type="date" name="" id="" v-model="start_date" class="rounded border border-gray-100 px-4 py-2 w-full">
        </div>
        <div class="col-span-4 w-full">
            <input type="date" name="" id="" v-model="end_date" class="rounded border border-gray-100 px-4 py-2 w-full">
        </div>
        <div class="col-span-4">
            <button class="px-4 py-3 text-white w-full bg-green-500 rounded hover:bg-green-600" @click="reportExcel()">Export</button>
        </div>
    </div>
</template>
<script>
var fileDownload = require('js-file-download');
import moment from 'moment'
import globalMixin from '../../mixin/global'

export default {
    props:['device'],
    data: () => ({
        start_date:moment().startOf('month').format('YYYY-MM-DD').toString(),
        end_date:moment().endOf('month').format('YYYY-MM-DD').toString(),
    }),
    mixins:[globalMixin],
    methods:{
          async reportExcel() {
            this.config.responseType = 'blob'
            this.axios.get(`v1/export_file?start_date=${this.start_date}&id=${this.device.id}&end_date=${this.end_date}`, this.config)
            .then(ress => { 
                fileDownload(ress.data,'Report.xlsx')
            })
        },
    }
}
</script>