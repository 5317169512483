export default {
    data: () => ({
        optionsReal:
            {
            series: [{
                name: 'Ketinggian Air [cm]',
                data: []
            }],
            animations: {
                enabled: true,
                easing: 'linear',
                dynamicAnimation: {
                    speed: 1000
                }
            },
            labels:[],
            chart: {
                type: 'area',
                stacked: false,
                height: 200,
                zoom: {
                    type: 'x',
                    enabled: true,
                    autoScaleYaxis: true
                },
                toolbar: {
                    autoSelected: 'zoom'
                }
            },
            dataLabels: {
                enabled: true,
                formatter: function(value, { series, seriesIndex, dataPointIndex, w }) {
                    return value + 'cm' 
                },
                style:{
                    fontSize: '10px',
                }

            },
            markers: {
                size: 0,
            },
            title: {
                
                align: 'left'
            },
            fill: {
                type: 'gradient',
                gradient: {
                    shadeIntensity: 1,
                    inverseColors: false,
                    opacityFrom: 0.9,
                    opacityTo: 0,
                    stops: [0, 90, 100]
                },
            },
            yaxis: {
               
                labels:{
                    formatter: (value) => { return value.toFixed(0) },
                },
                // title: {
                //     text: 'h [cm]'
                // },
              

            },
            xaxis: {
                type: 'datetime',
                labels: {
                    datetimeUTC: false
                },

            },
            tooltip: {
                x: {
                    format: 'dd MMM y',
                    floating: false,
                   
                },
                 y: {
                    formatter: function(value, { series, seriesIndex, dataPointIndex, w }) {
                      return value + ' ' + 'cm'
                    },
                    tickAmount: 2,
                  }
            },
            
            stroke: {
                show: true,
                curve: 'smooth',
                lineCap: 'round',
                width: 2,
                dashArray: 0,      
            }
        },
    }),
}