<template>
    <div>
        <qualityComponent v-if="reportDevice.type == 'WQ'"/>
        <levelComponent v-else/>
    </div>
</template>
<script>
import { mapActions, mapGetters} from 'vuex'
import levelComponent from './levelReportComponent/level'
import qualityComponent from './waterQualityComponent/index'
import globalMixin from '../../mixin/global'

var fileDownload = require('js-file-download');
export default {
    data: () => ({
       
    }),
    mixins:[globalMixin],
    methods:{

    
    },
    computed:{
        ...mapGetters({
            reportDevice:'device/reportDevice'
        })
      
    },
    watch:{
      
    },
    components:{
        levelComponent,qualityComponent
    },
    async created() {
       
    },
    mounted() {
       
    }
    
}
</script>