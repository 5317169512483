export default {
    data: () => ({
        optionBarDebit:
            {
            series: [],
            animations: {
                enabled: true,
                easing: 'linear',
                dynamicAnimation: {
                    speed: 1000
                }
            },
            labels:[2001, 2002, 2003, 2004, 2005, 2006, 2007],
            chart: {
                type: 'bar',
                // stacked: false,
                height: 200,
                zoom: {
                    type: 'x',
                    enabled: true,
                    autoScaleYaxis: true
                },
                toolbar: {
                    autoSelected: 'zoom'
                }
            },
            plotOptions: {
                bar: {
                  dataLabels: {
                    position: 'top', // top, center, bottom
                  },
                }
              },
            dataLabels: {
                enabled: true,
                formatter: function(value, { series, seriesIndex, dataPointIndex, w }) {
               
                  return value + 'm3/dt' 
                },
                offsetY: -20,
                  style: {
                    fontSize: '10px',
                    colors: [ '#A78BFA']
                  },
                  
            },
         
            markers: {
                size: 0,
            },
            title: {
                
                align: 'left'
            },
            colors: ['#A78BFA'],

            yaxis: {
                labels:{
                    formatter: (value) => { return value.toFixed(0) },
                },
              },
            tooltip: {
                // x: {
                //     format: 'y-MM-dd HH:mm:ss',
                // },
                 y: {
                    formatter: function(value, { series, seriesIndex, dataPointIndex, w }) {
                        let satuan = 'cm'
                        if (seriesIndex == 1) {
                            satuan = 'm'
                        } else if(seriesIndex == 2) {
                            satuan = 'm3/dt'
                        }
                      return value + ' ' + satuan
                    },
                    
                  }
            },
            
            stroke: {
                show: true,
                curve: 'stepline',
                lineCap: 'square',
                width: 2,
                dashArray: 0,      
            }
        },
    }),
}